import React from 'react';
import { StyleSheet } from 'react-native';

const TopicButtonImage = function ({ style, imageKey }) {
  const oneX = require(`./topic-${imageKey}-button.jpg`);
  const twoX = require(`./topic-${imageKey}-button@2x.jpg`);

  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { TopicButtonImage };
