import React, { useMemo, useEffect, useState } from 'react';
import { StyleSheet, View } from 'react-native';

import {
  AuthenticatedScreen,
  MAX_AUTH_SCREEN_CONTENT_WIDTH,
  MIN_AUTH_SCREEN_CONTENT_WIDTH,
} from './authenticatedScreen';
import { ActionButton } from '../components/actionButton';
import { ActionLink } from '../components/actionLink';
import { ContentBox } from '../components/contentBox';
import { ContentBoxRow } from '../components/contentBoxRow';
import { Heading, HeadingType } from '../components/heading';
import { Paragraph } from '../components/paragraph';
import { SubscribePopup } from '../components/popups/SubscribePopup';
import { ActivityPopup } from '../components/popups/activityPopup';
import { AgePopup } from '../components/popups/agePopup';
import { ResourcePopup } from '../components/popups/resourcePopup';
import { ResourcesList } from '../components/resourcesList';
import { ScreenBannerImage } from '../components/screenBannerImage';
import { ScreenLoadingIndicator } from '../components/screenLoadingIndicator';
import { TopicCarousel } from '../components/topicCarousel';
import { WarningText } from '../components/warningText';
import { useAuthContentStyles } from '../hooks/useAuthContentStyles';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { ScreenSizes, useScreenSize, useStylesForWidth } from '../hooks/useStyleForWidth';
import { LogoSmallDashboardImage } from '../img/logo-small-dashboard/LogoSmallDashboardImage';
import { ProgressIconImage } from '../img/progress-icon/ProgressIconImage';
import {
  useUserInfo,
  USER_FIELD_NAME,
  useApiRequest,
  ApiService,
  USER_FIELD_IS_TRIAL,
  USER_FIELD_AUTO_TUTORIAL,
  clearAutoTutorial,
  USER_FIELD_AGE_SURVEY_STATUS,
} from '../services/apiService';
import { staticTranslation } from '../services/staticTranslations';
import { AppColors, IconNames } from '../services/styles';
import {
  AgeSurveyStatus,
  AnalyticsScreenNames,
  MainSections,
  ResourceCategory,
} from '../util/enums';
import { percentCompletionForTopic } from '../util/utils';

const ACTIVITY = 'A';
const RESOURCE = 'R';

const DashboardContent = function (props) {
  const { screenSize, topics, resources } = props;
  const [progressBoxMaxWidth, setProgressBoxMaxWidth] = useState(
    screenSize === ScreenSizes.SM ? '100%' : 500
  );
  const completedTopics = useMemo(() => {
    return topics.filter(topic => percentCompletionForTopic(topic) === 100).length;
  }, [topics]);
  const completedAssessments = useMemo(() => {
    return topics.filter(topic => topic.user_assessment_completed).length;
  }, [topics]);
  const completedActivities = useMemo(() => {
    return topics.reduce((count, topic) => count + topic.done_exercises, 0);
  }, [topics]);
  const totalActivities = useMemo(() => {
    return topics.reduce((count, topic) => count + topic.total_exercises, 0);
  }, [topics]);
  const isTrialUser = useUserInfo(USER_FIELD_IS_TRIAL);

  return (
    <View>
      <View style={styles.dashTopicsHeader}>
        <Heading type={HeadingType.THREE} style={styles.dashTopicsTitle}>
          {staticTranslation('Topics')}
        </Heading>
        <ActionLink action={() => props.navigateTo('/topics')} color={AppColors.white}>
          {staticTranslation('View All')}
        </ActionLink>
      </View>
      <TopicCarousel
        onItemWidthKnown={screenSize === ScreenSizes.SM ? null : setProgressBoxMaxWidth}
        topics={topics}
        onSelect={topic => {
          if (isTrialUser && !topic.is_trial_content) {
            props.navigateTo('/dashboard?subscribe=1');
          } else {
            props.navigateTo('/topic_overview/' + topic.id);
          }
        }}
      />
      <View style={useStylesForWidth(styles, 'dashSecondaryInfo')}>
        <ResourcesList
          title={staticTranslation('Featured Resources')}
          displayCategories
          columns={screenSize >= ScreenSizes.LG ? 2 : 1}
          resources={resources}
          onShowResource={resource => {
            if (isTrialUser && !resource.is_trial_content) {
              props.navigateTo('/dashboard?subscribe=1');
            } else {
              const contentType = resource.activity_type ? ACTIVITY : RESOURCE;
              props.navigateTo(
                '/dashboard?featuredItemId=' + resource.id + '&contentType=' + contentType
              );
            }
          }}
          style={useStylesForWidth(styles, 'dashResourceList')}
        />
        <ContentBox
          style={useStylesForWidth(styles, 'dashProgressContentBox', {
            maxWidth: progressBoxMaxWidth,
          })}>
          <View style={styles.dashProgressHeader}>
            <ProgressIconImage style={styles.dashProgressIcon} />
            <Heading type={HeadingType.FIVE} style={styles.dashProgressTitle}>
              {staticTranslation('Track Your Progress')}
            </Heading>
          </View>
          <ContentBoxRow
            left={<Heading type={HeadingType.FOUR}>{staticTranslation('Topics')}</Heading>}
            right={
              <Paragraph bold style={styles.dashProgressStat}>
                {completedTopics}/{topics.length}
              </Paragraph>
            }
          />
          <ContentBoxRow
            left={<Heading type={HeadingType.FOUR}>{staticTranslation('Assessments')}</Heading>}
            right={
              <Paragraph bold style={styles.dashProgressStat}>
                {completedAssessments}/{topics.length}
              </Paragraph>
            }
          />
          <ContentBoxRow
            left={<Heading type={HeadingType.FOUR}>{staticTranslation('Activities')}</Heading>}
            right={
              <Paragraph bold style={styles.dashProgressStat}>
                {completedActivities}/{totalActivities}
              </Paragraph>
            }
          />
          <ActionButton
            title={staticTranslation('View my progress')}
            style={styles.dashProgressButton}
            action={() => props.navigateTo('/progress')}
            rightIcon={IconNames.chevronRight}
          />
        </ContentBox>
      </View>
    </View>
  );
};

const daysSinceAgeSurveyOffered = currentStatus => {
  if (typeof currentStatus !== 'string') {
    return -1;
  }
  const pair = currentStatus.split('_');
  if (pair.length !== 2 || pair[1].length !== 8 || isNaN(pair[1])) {
    return -1;
  }
  try {
    const mm = parseInt(pair[1].substring(0, 2), 10);
    const dd = parseInt(pair[1].substring(2, 2), 10);
    const yy = parseInt(pair[1].substring(4), 10);
    const d = new Date(yy, mm - 1, dd);
    return Math.floor((new Date().getTime() - d.getTime()) / 86400000);
  } catch (e) {
    console.log(e);
    return -1;
  }
};

const DashboardScreen = function (props) {
  useScreenTitle('');
  useScreenView(AnalyticsScreenNames.dashboard, 'DashboardScreen');
  const screenSize = useScreenSize();
  const topicsRequest = useApiRequest(
    cb => {
      return ApiService.getModules(cb);
    },
    [],
    true
  );
  const topics = useMemo(() => {
    return (topicsRequest.data || {}).modules || [];
  }, [topicsRequest.data]);
  const resourcesRequest = useApiRequest(
    cb => {
      return ApiService.getFeaturedResources(cb);
    },
    [],
    true
  );
  const activitiesRequest = useApiRequest(
    cb => {
      return ApiService.getActivities(true, cb);
    },
    [],
    true
  );
  const completedAnything = useMemo(() => {
    return topics.filter(topic => percentCompletionForTopic(topic) > 0).length > 0;
  }, [topics]);
  const featuredItems = useMemo(() => {
    const array = [
      {
        id: 0,
        title: staticTranslation('Introduction to InnerAccess'),
        category: ResourceCategory.Tutorial,
        is_trial_content: true,
      },
      ...((activitiesRequest.data || {}).activities || []),
      ...((resourcesRequest.data || {}).resources || []),
    ];
    if (array.length > 8) {
      return array.slice(0, 8);
    }
    return array;
  }, [resourcesRequest, activitiesRequest]);
  const userName = useUserInfo(USER_FIELD_NAME);
  const firstName = userName.indexOf(' ') > -1 ? userName.split(' ')[0] : userName;
  const selectedFeaturedItem = featuredItems.filter(r => {
    if (props.contentType === ACTIVITY) {
      return r.activity_type && r.id === parseInt(props.featuredItemId, 10);
    }
    return !r.activity_type && r.id === parseInt(props.featuredItemId, 10);
  });
  const showSubscriptionPopup = props.subscribe;
  const ageSurveyStatus = useUserInfo(USER_FIELD_AGE_SURVEY_STATUS);
  const showAgePopup =
    ageSurveyStatus === AgeSurveyStatus.Completed ||
    ageSurveyStatus === AgeSurveyStatus.NotApplicable
      ? false
      : ageSurveyStatus === AgeSurveyStatus.NotYetOffered ||
        daysSinceAgeSurveyOffered(ageSurveyStatus) > 7;
  const closePopup = () => props.navigateTo('/dashboard');
  const autoTutorial = useUserInfo(USER_FIELD_AUTO_TUTORIAL);

  useEffect(() => {
    if (autoTutorial) {
      props.navigateTo(
        '/dashboard?featuredItemId=' + featuredItems[0].id + '&contentType=' + RESOURCE
      );
      clearAutoTutorial();
    }
  });

  return (
    <>
      <AuthenticatedScreen {...props} currentSection={MainSections.DASHBOARD}>
        <ScreenBannerImage />
        <View style={useStylesForWidth(styles, 'dashContent', ...useAuthContentStyles())}>
          {screenSize === ScreenSizes.SM ? <LogoSmallDashboardImage /> : null}
          <View style={useStylesForWidth(styles, 'dashWelcome')}>
            <Heading style={useStylesForWidth(styles, 'dashWelcomeTitle')}>
              {staticTranslation('Hi :name,', { name: firstName })}
            </Heading>
            <Heading type={HeadingType.THREE} style={styles.dashWelcomeSubtitle}>
              {staticTranslation(`Ready to ${completedAnything ? 'keep going' : 'get started'}?`)}
            </Heading>
          </View>
          {topicsRequest.isBusy ? (
            <ScreenLoadingIndicator color={AppColors.white} center />
          ) : topicsRequest.error ? (
            <WarningText>{topicsRequest.error}</WarningText>
          ) : (
            <DashboardContent
              screenSize={screenSize}
              {...props}
              topics={topics}
              resources={featuredItems}
            />
          )}
        </View>
      </AuthenticatedScreen>
      {showAgePopup ? (
        <AgePopup />
      ) : showSubscriptionPopup ? (
        <SubscribePopup close={closePopup} />
      ) : !selectedFeaturedItem.length ? null : props.contentType === ACTIVITY ? (
        <ActivityPopup
          trackProgress={false}
          activity={selectedFeaturedItem[0]}
          close={closePopup}
        />
      ) : (
        <ResourcePopup close={closePopup} resource={selectedFeaturedItem[0]} />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  dashContent: {
    maxWidth: MAX_AUTH_SCREEN_CONTENT_WIDTH,
  },
  dashContentMDLGXL: {
    minWidth: MIN_AUTH_SCREEN_CONTENT_WIDTH,
  },
  dashWelcomeSM: {
    marginBottom: 30,
  },
  dashWelcomeMDLGXL: {
    marginBottom: 60,
  },
  dashWelcomeTitle: {
    color: AppColors.white,
    marginBottom: 10,
  },
  dashWelcomeTitleSM: {
    marginTop: 10,
    marginBottom: 0,
  },
  dashWelcomeSubtitle: {
    color: AppColors.white,
    opacity: 0.5,
  },
  dashTopicsHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dashTopicsTitle: {
    color: AppColors.white,
    marginBottom: 0,
  },
  dashSecondaryInfo: {
    width: '100%',
    justifyContent: 'space-between',
    marginTop: 40,
  },
  dashSecondaryInfoSM: {
    flexDirection: 'column-reverse',
  },
  dashSecondaryInfoMDLGXL: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  dashResourceListMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
  },
  dashResourceListMD: {
    width: '50%',
  },
  dashResourceListLGXL: {
    width: '60%',
  },
  dashProgressContentBoxMDLGXL: {
    flexGrow: 1,
    flexShrink: 1,
  },
  dashProgressContentBoxMD: {
    width: '50%',
  },
  dashProgressContentBoxLGXL: {
    width: '40%',
  },
  dashProgressContentBoxSM: {
    marginBottom: 40,
  },
  dashProgressHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dashProgressIcon: {
    width: 40,
    height: 31,
    marginRight: 10,
  },
  dashProgressTitle: {
    marginBottom: 0,
  },
  dashProgressStat: { color: AppColors.brandedOrange },
  dashProgressButton: {
    marginTop: 20,
  },
});

export { DashboardScreen };
