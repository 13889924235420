import React, { useState, useEffect, useRef } from 'react';
import { StyleSheet, View, ScrollView, Platform } from 'react-native';

import { ModalPopup } from './modalPopup';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../../hooks/useStyleForWidth';
import { OnboardingAssessmentsImage } from '../../img/onboarding/OnboardingAssessmentsImage';
import { OnboardingDashboardImage } from '../../img/onboarding/OnboardingDashboardImage';
import { OnboardingResourcesImage } from '../../img/onboarding/OnboardingResourcesImage';
import { OnboardingTopicsImage } from '../../img/onboarding/OnboardingTopicsImage';
import { OnboardingWelcomeImage } from '../../img/onboarding/OnboardingWelcomeImage';
import { OnboardingAssessmentsLargeImage } from '../../img/onboarding-large/OnboardingAssessmentsLargeImage';
import { OnboardingDashboardLargeImage } from '../../img/onboarding-large/OnboardingDashboardLargeImage';
import { OnboardingResourcesLargeImage } from '../../img/onboarding-large/OnboardingResourcesLargeImage';
import { OnboardingTopicsLargeImage } from '../../img/onboarding-large/OnboardingTopicsLargeImage';
import { OnboardingWelcomeLargeImage } from '../../img/onboarding-large/OnboardingWelcomeLargeImage';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors, AppStyles, IconNames } from '../../services/styles';
import { analyticsTutorialBegin, analyticsTutorialComplete } from '../../util/firebaseHelper';
import { ActionButton, ActionButtonType } from '../actionButton';
import { ActionLink } from '../actionLink';
import { Heading } from '../heading';
import { Paragraph } from '../paragraph';
import { RichTextDisplay } from '../richTextDisplay';

const MDLGXL_WIDTH = 726;

const pages = [
  {
    image: OnboardingWelcomeImage,
    largeImage: OnboardingWelcomeLargeImage,
    title: 'Welcome!',
    description: 'Take this quick tour to learn about our most important features!',
  },
  {
    image: OnboardingDashboardImage,
    largeImage: OnboardingDashboardLargeImage,
    title: 'Dashboard',
    description: 'Access content, activities, and see your progress.',
  },
  {
    image: OnboardingTopicsImage,
    largeImage: OnboardingTopicsLargeImage,
    title: 'Topics',
    description: 'Let Melanie guide you on your journey through the 8 InnerAccess topics.',
  },
  {
    image: OnboardingResourcesImage,
    largeImage: OnboardingResourcesLargeImage,
    title: 'Activities & Resources',
    description: 'Come alive inside with guided meditations and journaling.',
  },
  {
    image: OnboardingAssessmentsImage,
    largeImage: OnboardingAssessmentsLargeImage,
    title: 'Self Assessments',
    description: 'Assessments help you track your personal growth.',
  },
];

const TutorialPopup = function (props) {
  const { close } = props;
  const screenWidth = useScreenDimensions().width;
  const screenHeight = useScreenDimensions().height;
  const isSM = useScreenSize() === ScreenSizes.SM;
  const width = isSM ? screenWidth : MDLGXL_WIDTH;
  const imageHeight = isSM ? screenWidth / (375 / 814) : 446;
  const minHeightStyle = isSM ? {} : { minHeight: Math.min(690, screenHeight * 0.8) };
  const [currentPage, setCurrentPage] = useState(0);
  const scrollRef = useRef(null);
  const welcomeScrollRef = useRef(null);
  const onLastPage = currentPage === pages.length - 1;

  const nextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  useEffect(() => analyticsTutorialBegin(), []);

  useEffect(() => {
    if (welcomeScrollRef.current) {
      welcomeScrollRef.current.flashScrollIndicators();
    }
  }, [welcomeScrollRef]);

  useEffect(() => {
    const x = width * currentPage;
    // noinspection JSUnresolvedFunction
    scrollRef.current.scrollTo({ x, y: 0, animated: true });
  }, [currentPage, width]);

  const introHTML = [
    `<p>${staticTranslation(
      'Keep an open mind and heart – you’ll likely discover plenty of new information here. Be curious!'
    )}</p>`,
    `<p>${staticTranslation(
      'Stay in touch with why you’re doing InnerAccess – it will help you get the most out of it.'
    )}</p>`,
    `<p>${staticTranslation(
      'For best results, complete the program in order. You can go back and review any parts of it as many times as you like.'
    )}</p>`,
    `<p>${staticTranslation(
      'InnerAccess is designed to help you find answers to difficult questions. Stick with it – the program works if you work it!'
    )}</p>`,
  ].join('');
  const welcomePageContentHeight =
    Platform.OS === 'web' ? { maxHeight: '80%', height: 'auto' } : { height: '80%' };
  return (
    <ModalPopup style={minHeightStyle} width={MDLGXL_WIDTH}>
      <ScrollView
        horizontal
        style={styles.ppScroller}
        ref={scrollRef}
        scrollEnabled={false}
        pagingEnabled>
        {pages.map((page, index) => {
          const ImageClass = isSM ? page.image : page.largeImage;
          return (
            <ImageClass key={index} style={[styles.ppImage, { width, height: imageHeight }]} />
          );
        })}
      </ScrollView>
      <View
        style={useStylesForWidth(
          styles,
          'ppContent',
          currentPage !== 0 ? {} : welcomePageContentHeight
        )}>
        <Heading style={styles.ppHeading}>{staticTranslation(pages[currentPage].title)}</Heading>
        {currentPage === 0 ? (
          <ScrollView style={styles.ppBody} ref={welcomeScrollRef}>
            <RichTextDisplay html={introHTML} />
          </ScrollView>
        ) : (
          <Paragraph style={styles.ppBody}>
            {staticTranslation(pages[currentPage].description)}
          </Paragraph>
        )}
        <ActionButton
          rightIcon={onLastPage ? null : IconNames.chevronRight}
          action={
            onLastPage
              ? () => {
                  analyticsTutorialComplete();
                  close();
                }
              : nextPage
          }
          type={ActionButtonType.SECONDARY}>
          {staticTranslation(
            onLastPage ? "Let's Get Started!" : currentPage === 0 ? 'Take a tour' : 'Next'
          )}
        </ActionButton>
      </View>
      <View style={useStylesForWidth(styles, 'ppNavView')}>
        <View style={styles.ppDots}>
          {pages.map((page, index) => {
            return (
              <View
                key={index}
                style={[styles.ppDot, index === currentPage ? styles.ppActiveDot : {}]}
              />
            );
          })}
        </View>
        <ActionLink action={close} bold color={AppColors.white} style={styles.ppSkipLink}>
          {staticTranslation('Skip')}
        </ActionLink>
      </View>
    </ModalPopup>
  );
};

const styles = StyleSheet.create({
  ppScroller: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    right: 0,
  },
  ppImage: {},
  ppNavView: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    height: 100,
  },
  ppNavViewSM: {
    paddingLeft: AppStyles.modalPaddingSM,
    paddingRight: AppStyles.modalPaddingSM,
  },
  ppNavViewMDLGXL: {
    paddingLeft: AppStyles.modalPaddingMDLGXL,
    paddingRight: AppStyles.modalPaddingMDLGXL,
  },
  ppDots: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  ppDot: {
    height: 6,
    width: 20,
    borderRadius: 3,
    marginRight: 5,
    backgroundColor: AppColors.withOpacity(AppColors.white, 0.5),
  },
  ppActiveDot: {
    backgroundColor: AppColors.white,
  },
  ppSkipLink: {
    width: 60,
    alignItems: 'flex-end',
    height: 40,
  },
  ppContent: {
    ...(Platform.OS === 'web'
      ? {
          transform: 'translateZ(0)', //fix bug in mobile safari causing ppContent to not always render on top of the background image
        }
      : {}),
    backgroundColor: AppColors.white,
    marginTop: AppStyles.largeBorderRadius * -1,
    padding: AppStyles.modalPaddingMDLGXL,
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 250,
    zIndex: 2,
    justifyContent: 'space-between',
  },
  ppContentSM: {
    height: 270,
    padding: AppStyles.modalPaddingSM,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
  },
  ppHeading: {
    marginBottom: 10,
  },
  ppBody: {
    marginBottom: 10,
  },
});

export { TutorialPopup };
