import { Platform } from 'react-native';

const AppColors = {
  withOpacity: (color, percent) => {
    const opacity = '0' + Math.round(255 * percent).toString(16);
    return color + opacity.substr(opacity.length - 2);
  },
  white: '#ffffff',
  brandedBlue: '#a072a8', //blue used all over
  brandedOrange: '#99b378',
  brandedTeal: '#80cfd8',
  errorText: '#ff0000',
  darkestText: '#1E1F20', //smaller headings before login, link before login, main menu inactive at 30% opacity, some small text after login
  darkerText: '#2D3142', //smaller headings after login
  darkText: '#4D4D4F', //body text, needs 60% opacity | small headings and links after login
  largeHeadingText: '#575759', //heading one
  greyText: '#8F92A1', //body on topic pages
  lightText: '#9C9EB9', //subtitles after login
  placeholderText: '#ACACAC',
  mainMenuText: '#BBBBBC',
  menuIconBackground: '#DEDFE3',
  mainBackground: '#F4F6FA',
  lightBackground: '#F8F8F8',
  dangerRed: '#dc3545',
  //anonymous screens:

  //auth screens:
  //main menu link: 14px Rubik 500 #3170B8 (active) 1E1F20 @ 30% opacity (inactive) border radius 12 #F4F6FA(background active)
};

const AppStyles = {
  mainAuthScreenPaddingSM: 20,
  mainAuthScreenPaddingMDLG: 40,
  mainAuthScreenPaddingXL: 80,

  modalPaddingSM: 30,
  modalPaddingMDLGXL: 40,

  fontFamily: 'Rubik',
  fontFamilyBold: Platform.OS === 'web' ? 'Rubik' : 'Rubik-Medium',

  tinyTextSize: 10,
  tinyTextHeight: 16,
  smallTextSize: 14,
  smallTextHeight: 23,
  normalTextSize: 16,
  normalTextHeight: 26,
  largeTextSize: 20,
  largeTextHeight: 30,
  largerTextSize: 24,
  largerTextHeight: 34,
  largestTextSize: 32,
  largestTextHeight: 42,

  boldWeight: '500',

  tinyBorderRadius: 4,
  smallBorderRadius: 8, //icon buttons
  normalBorderRadius: 12, //buttons, topics, content boxes
  fieldBorderRadius: 15, //text fields
  largeBorderRadius: 32, //anonymous screen overlap
};

const IconSizes = {
  small: 18, //based on optimal sizes https://google.github.io/material-design-icons/
  normal: 24,
  large: 36,
  xlarge: 48,
};

const IconNames = {
  chevronLeft: 'chevron_left',
  chevronRight: 'chevron_right',
  chevronDown: 'expand_more',
  chevronUp: 'expand_less',
  assessment: 'poll',
  checkmark: 'check',
  trackProgress: 'visibility',
  lock: 'lock',
  skipBack30: 'replay_30',
  skipForward30: 'forward_30',
  play: 'play_arrow',
  pause: 'pause',
  dashboard: 'dashboard',
  library: 'video_library',
  resources: 'library_books',
  progress: 'insert_chart',
  account: 'person_outline',
  audio: 'volume_up',
  book: 'import_contacts',
  movie: 'local_movies',
  download: 'get_app',
  close: 'close',
  writtenActivity: 'description',
  clock: 'access_time',
  pencil: 'edit',
  logout: 'exit_to_app',
  tutorial: 'mood',
  openInNew: 'open_in_new',
  giftCard: 'card_giftcard',
  music: 'music_note',
};

export { AppColors, AppStyles, IconSizes, IconNames };
