import React from 'react';
import { StyleSheet } from 'react-native';

const TopicDetailsBannerImage = function ({ style }) {
  const oneX = require('./topic-details-banner.jpg');
  const twoX = require('./topic-details-banner@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { TopicDetailsBannerImage };
