import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { ActionButton, ActionButtonType } from '../components/actionButton';
import { OrLine } from '../components/orLine';
import { Paragraph } from '../components/paragraph';
import { useLocale } from '../hooks/useLocale';
import { useScreenTitle } from '../hooks/useScreenTitle';
import { useScreenView } from '../hooks/useScreenView';
import { staticTranslation } from '../services/staticTranslations';
import { AnalyticsScreenNames } from '../util/enums';
import { env } from '../util/env';

const DisabledScreen = function (props) {
  const locale = useLocale();
  useScreenTitle('');
  useScreenView(AnalyticsScreenNames.disabled, 'DisabledScreen');

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(false);
  }, [props]);

  return (
    <>
      <Paragraph style={styles.disabledParagraph}>
        {staticTranslation(locale, 'Your account has been disabled.')}
      </Paragraph>
      <ActionButton
        action={() => props.navigateTo('/logout')}
        title={staticTranslation(locale, 'Logout')}
      />
      <OrLine />
      <Paragraph style={styles.disabledParagraph}>
        {staticTranslation(locale, 'If you require further assistance, please get in touch.')}
      </Paragraph>
      <ActionButton
        type={ActionButtonType.SECONDARY}
        openUrl={`mailto:${env.contactEmail}?subject=${staticTranslation(
          locale,
          'App Support Required'
        )}`}>
        {staticTranslation(locale, 'Contact Us')}
      </ActionButton>
    </>
  );
};

const styles = StyleSheet.create({
  disabledParagraph: {
    marginBottom: 20,
  },
});

export { DisabledScreen };
