import React from 'react';
import { StyleSheet } from 'react-native';

const ProgressIconImage = function ({ style }) {
  const oneX = require('./progress-icon.png');
  const twoX = require('./progress-icon@2x.png');
  return <img src={twoX} style={StyleSheet.flatten(style)} srcSet={`${oneX}, ${twoX} 2x`} alt="" />;
};

export { ProgressIconImage };
