import React, { useState, useEffect } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';

import { ModalPopup } from './modalPopup';
import { useStylesForWidth } from '../../hooks/useStyleForWidth';
import {
  ApiService,
  markAgeSurveyComplete,
  useApiRequest,
  USER_FIELD_INSTITUTION_ID,
  useUserInfo,
} from '../../services/apiService';
import { staticTranslation } from '../../services/staticTranslations';
import { AppColors, AppStyles, IconNames, IconSizes } from '../../services/styles';
import { AgeSurveyAnswer, AnalyticsContentTypes } from '../../util/enums';
import { analyticsSelectContent } from '../../util/firebaseHelper';
import { noop } from '../../util/utils';
import { ActionButton } from '../actionButton';
import { Heading, HeadingType } from '../heading';
import { Icon } from '../icon';
import { Paragraph } from '../paragraph';
import { RadioButton } from '../radioButton';
import { WarningText } from '../warningText';

const AgePopup = function () {
  const institutionCodeId = useUserInfo(USER_FIELD_INSTITUTION_ID);
  const [answer, setAnswer] = useState(0);
  const submitAnswerApiRequest = useApiRequest(
    cb => {
      return ApiService.submitAgeSurveyResponse(answer, institutionCodeId, cb);
    },
    [answer, institutionCodeId]
  );
  const markAsShownApiRequest = useApiRequest(cb => {
    return ApiService.markAgeSurveyOffered(cb);
  }, []);
  const answerSent = submitAnswerApiRequest.succeeded;
  const answerSubmissionError = submitAnswerApiRequest.error;
  const paddingStyle = useStylesForWidth(styles, 'agePadding');
  const ageSurveyOptions = AgeSurveyAnswer.toArray();
  const lastRadioButtonStyle = useStylesForWidth(styles, 'ageLastRadioButton');

  useEffect(() => analyticsSelectContent(AnalyticsContentTypes.ageSurvey, 'ageSurvey'), []);

  const questionView = () => {
    return (
      <ScrollView style={styles.ageBody}>
        <View style={[paddingStyle, styles.ageBodyContent]}>
          <Paragraph style={styles.ageBodyText}>
            {staticTranslation(
              'Sharing your age range helps us better understand who is using InnerAccess.'
            )}
          </Paragraph>
          <View style={styles.ageRadioButtons}>
            {ageSurveyOptions.map((option, i) => {
              return (
                <RadioButton
                  selected={answer === option}
                  action={() => setAnswer(option)}
                  key={`answerOption${option}`}
                  style={[
                    styles.ageRadioButton,
                    i === ageSurveyOptions.length - 1 ? lastRadioButtonStyle : {},
                  ]}>
                  <Paragraph style={styles.ageRadioButtonText}>
                    {staticTranslation(AgeSurveyAnswer.getDescription(option))}
                  </Paragraph>
                </RadioButton>
              );
            })}
          </View>
          <WarningText>
            {answerSubmissionError
              ? `${staticTranslation(
                  'There was a problem saving these changes:'
                )} ${answerSubmissionError}`
              : null}
          </WarningText>
        </View>
        <View style={[paddingStyle, styles.ageButtons]}>
          <ActionButton
            action={() => submitAnswerApiRequest.send()}
            disabled={submitAnswerApiRequest.isBusy || markAsShownApiRequest.isBusy || !answer}
            showActivityIndicator={submitAnswerApiRequest.isBusy || markAsShownApiRequest.isBusy}
            title={staticTranslation('Submit')}
          />
        </View>
      </ScrollView>
    );
  };

  const thanksView = () => {
    return (
      <View style={styles.ageBody}>
        <View style={[paddingStyle, styles.ageBodyContent]}>
          <Paragraph style={styles.ageBodyText}>
            {staticTranslation('Thanks for sharing!')}
          </Paragraph>
          <Paragraph style={styles.ageBodyText}>
            {staticTranslation('Your contribution helps us enhance the program.')}
          </Paragraph>
        </View>
        <View style={[paddingStyle, styles.ageButtons]}>
          <ActionButton action={() => markAgeSurveyComplete()} title={staticTranslation('Done')} />
        </View>
      </View>
    );
  };
  return (
    <ModalPopup
      isBelowSafeArea
      closeColor={AppColors.brandedBlue}
      close={
        submitAnswerApiRequest.isBusy || markAsShownApiRequest.isBusy
          ? noop
          : answerSent
            ? () => markAgeSurveyComplete()
            : () => markAsShownApiRequest.send()
      }
      style={useStylesForWidth(styles, 'ageModal')}>
      <View style={[paddingStyle, styles.ageHeadingView]}>
        <Icon color={AppColors.greyText} size={IconSizes.normal} name={IconNames.assessment} />
        <Heading style={[styles.ageHeading]} type={HeadingType.FOUR}>
          {staticTranslation('Age Survey')}
        </Heading>
      </View>
      {answerSent ? thanksView() : questionView()}
    </ModalPopup>
  );
};

const styles = StyleSheet.create({
  ageModalSM: {
    top: '20%',
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    borderTopRightRadius: AppStyles.largeBorderRadius,
  },
  agePaddingSM: {
    padding: AppStyles.modalPaddingSM,
  },
  agePaddingMDLGXL: {
    padding: AppStyles.modalPaddingMDLGXL,
  },
  ageHeadingView: {
    paddingTop: 20,
    paddingRight: 50,
    paddingBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 3,
    borderBottomColor: AppColors.lightBackground,
  },
  ageHeading: {
    color: AppColors.greyText,
    textTransform: 'uppercase',
    marginBottom: 0,
    marginLeft: 10,
    lineHeight: 40,
  },
  ageBody: {
    flexGrow: 1,
    flexShrink: 1,
  },
  ageBodyContent: {
    minHeight: 200,
    paddingTop: 20,
    paddingBottom: 0,
  },
  ageBodyText: {
    padding: 0,
    paddingBottom: 10,
  },
  ageRadioButtons: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  ageRadioButton: {
    width: '50%',
    marginBottom: 10,
  },
  ageLastRadioButtonSM: {
    width: '100%',
  },
  ageRadioButtonText: {
    color: AppColors.darkText,
  },
  ageButtons: {
    paddingTop: 20,
  },
});

export { AgePopup };
