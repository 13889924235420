import React from 'react';
import { StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { staticTranslation } from '../../services/staticTranslations';
import { Languages } from '../../util/enums';

const WtcLogoImage = function ({ style }) {
  const lang = useLocale();
  const oneX =
    lang === Languages.french ? require('./wtc-logo-fr.png') : require('./wtc-logo-en.png');
  const twoX =
    lang === Languages.french ? require('./wtc-logo-fr@2x.png') : require('./wtc-logo-en@2x.png');
  const threeX =
    lang === Languages.french ? require('./wtc-logo-fr@3x.png') : require('./wtc-logo-en@3x.png');
  return (
    <img
      src={threeX}
      alt={staticTranslation(lang, 'Wellness Together Canada')}
      style={StyleSheet.flatten(style)}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
    />
  );
};

export { WtcLogoImage };
