import React from 'react';
import { StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { useStaticTranslation } from '../../services/staticTranslations';
import { Languages } from '../../util/enums';

const LogoWithNameImage = function ({ style }) {
  const useFrench = useLocale() === Languages.french;
  const oneX = useFrench ? require('./logo-with-name-fr.png') : require('./logo-with-name.png');
  const twoX = useFrench
    ? require('./logo-with-name-fr@2x.png')
    : require('./logo-with-name@2x.png');
  const threeX = useFrench
    ? require('./logo-with-name-fr@3x.png')
    : require('./logo-with-name@3x.png');
  const width = useFrench ? 221 : 261;
  return (
    <img
      src={threeX}
      style={StyleSheet.flatten([{ height: 'auto', width }, style])}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
      alt={useStaticTranslation('InnerAccess')}
    />
  );
};

export { LogoWithNameImage };
