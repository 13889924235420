import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Paragraph } from '../shared/components/paragraph';
import { WtcLogoImage } from '../shared/img/wtc-logo/WtcLogoImage';
import { View } from 'react-native-web';
import { Heading } from '../shared/components/heading';
import { WtcLoginButton } from '../shared/components/wtcLoginButton';
import { useScreenTitle } from '../shared/hooks/useScreenTitle';
import { useScreenView } from '../shared/hooks/useScreenView';
import { AnalyticsScreenNames } from '../shared/util/enums';
import { ScreenSizes, useScreenSize } from '../shared/hooks/useStyleForWidth';
import { ActionLink } from '../shared/components/actionLink';
import { env } from '../shared/util/env';
import { ParagraphSizes } from '../shared/components/paragraph';
import { AppColors } from '../shared/services/styles';
import { useStylesForWidth } from '../shared/hooks/useStyleForWidth';
import { AnonymousLanguageToggle } from '../shared/components/anonymousLanguageToggle';
import { staticTranslation } from '../shared/services/staticTranslations';

let WTCWelcomeScreen = function (props) {
  const message = staticTranslation(
    'Sign in with your WTC account for quick and easy access to all of our videos, meditations, activities, and more!'
  );
  useScreenTitle(staticTranslation('Wellness Together Canada'));
  useScreenView(AnalyticsScreenNames.wtcLogin, 'WTCWelcomeScreen');
  const isSM = useScreenSize() === ScreenSizes.SM;

  useEffect(() => {
    props.setHideLogo(isSM);
    props.setWelcomeMessageForSM(message);
    props.setAvoidKeyboard(false);
  }, [props, isSM, message]);

  const welcomeView = isSM ? null : (
    <View>
      <Heading>{staticTranslation('Welcome to InnerAccess')}</Heading>
      <Paragraph style={styles.wtcDescription}>{message}</Paragraph>
    </View>
  );

  return (
    <View style={useStylesForWidth(styles, 'wtcContent')}>
      {welcomeView}
      <WtcLoginButton style={useStylesForWidth(styles, 'wtcButtonSM')} />
      <ActionLink
        size={ParagraphSizes.SMALL}
        style={useStylesForWidth(styles, 'wtcLink')}
        color={AppColors.brandedBlue}
        bold={true}
        openUrl={env.websiteUrl}
      >
        {staticTranslation('Learn more about InnerAccess')}
      </ActionLink>
      <WtcLogoImage style={styles.wtcLogo} />
      <AnonymousLanguageToggle />
    </View>
  );
};

const styles = StyleSheet.create({
  wtcContentSM: {
    alignItems: 'center',
  },
  wtcLogo: {
    width: '100%',
    maxWidth: 350,
    marginTop: 40,
  },
  wtcDescription: {
    marginBottom: 40,
  },
  wtcLinkMDLGXL: {
    marginTop: 0,
  },
  wtcButtonSM: {
    maxWidth: 350,
  },
});

export { WTCWelcomeScreen };
