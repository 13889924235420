import { useState, useEffect } from 'react';

import { GlobalPubSub, LOCALE_CHANGED_EVENT } from './pubsub';
import { getLocale, useLocale } from '../hooks/useLocale';
import English from '../resources/text/en.json';
import Spanish from '../resources/text/es.json';
import French from '../resources/text/fr.json';
import { Languages } from '../util/enums';
import { env } from '../util/env';

function interpolate(value, interpolations) {
  return value.replace(/:([a-zA-Z0-9_]+)/g, (match, key) => {
    if (interpolations && interpolations.hasOwnProperty(key)) {
      return interpolations[key];
    } else if (env.hasOwnProperty(key)) {
      return env[key];
    }
    return match;
  });
}

function translate (locale, key) {
  const dictionaries = {
    [Languages.french]: French,
    [Languages.spanish]: Spanish,
    [Languages.english]: English
  };
  const dictionary = dictionaries[locale] || English;
  return dictionary[key] || key;
}

let memLocale = null;
memLocale = getLocale(locale => (memLocale = locale));
GlobalPubSub.on(LOCALE_CHANGED_EVENT, locale => (memLocale = locale));

function staticTranslation (a, b, c) {
  //acceptable signatures:

  //preferred signature:
  //staticTranslation(locale, key)
  //staticTranslation(locale, key, interpolations)

  //use either of these if there is guaranteed no need for re-render after locale changes:
  //staticTranslation(key)
  //staticTranslation(key, interpolations)

  const key = arguments.length === 1 || typeof b !== 'string' ? a : b;
  const locale = arguments.length === 3 || typeof b === 'string' ? a : null;
  const interpolations =
    arguments.length === 3 ? c : arguments.length === 1 ? null : typeof b === 'string' ? null : b;
  return interpolate(translate(locale || memLocale, key), interpolations);
}

// hook version:
function useStaticTranslation (key, interpolations) {
  const locale = useLocale();
  const [translation, setTranslation] = useState(translate(locale, key));
  useEffect(() => {
    setTranslation(translate(locale, key));
  }, [locale, key]);
  return interpolate(translation, interpolations);
}

export { staticTranslation, useStaticTranslation };
