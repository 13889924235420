/*
 * See deploy_in_production.sh to see which env are set for the production web instance
 * For mobile builds, comment out the appropriate blocks in the env variable below.
 *
 * environment
 * what type of instance is running: local, dev, staging, or production
 * used by mobile and web builds
 *
 * apiBaseUrl
 * what is the backend API url
 * used by mobile and web builds
 *
 * vimeoBaseUrl
 * used for local testing only, to make vimeo videos load correctly
 * used by mobile builds only
 *
 * webAppBaseUrl
 * the frontend web app base url
 * used by mobile and web builds
 *
 * wtcBaseUrl
 * The base url for WTC auth endpoints
 * used by web builds only
 * https://app.wellnesstogether.grnspace.ca in staging
 * https://app.wellnesstogether.ca in production
 *
 * wtcClientId
 * The client ID to send to WTC for SSO logins
 * used by web builds only
 * tV7bDiM3ckfbm4sNFhlijH1Pf8tApyAbm6tRNA3m in staging
 * not sure about production yet
 *
 * wtcDisable
 * Boolean, set to true to hide the "Sign In With WTC" functionality
 * used by mobile and web builds
 */
let env = {
  //for local web and mobile development:
  //in web production, the ENV are picked up here from the process.env
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'http://cinim-web.test',
  // vimeoBaseUrl: 'https://cinim.artisanapps.io',
  webAppBaseUrl: process.env.REACT_APP_APP_BASE_URL || 'http://app.cinim-web.test:3000',
  wtcBaseUrl: process.env.REACT_APP_WTC_BASE_URL || 'https://app.wellnesstogether.grnspace.ca',
  wtcClientId: process.env.REACT_APP_WTC_CLIENT_ID || 'NOT_SUPPORTED',
  wtcDisable: process.env.REACT_APP_WTC_DISABLE || false,

  contactEmail: 'breathingroom@cinim.org',
  privacyPolicyUrl: 'https://www.breathingroom.me/privacy-policy/',
  institutionCodeHelpUrl: 'https://www.breathingroom.me/schools-institutions',
  websiteUrl: 'https://www.breathingroom.me',
  termsOfUseUrl: 'https://www.breathingroom.me/terms-of-use/',
  appStoreUrl: 'https://apps.apple.com/ca/app/breathingroom/id923465123',
  iosReviewUrl: 'https://apps.apple.com/ca/app/breathingroom/id923465123?action=write-review',
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=cinim.BreathingRoom'

  //below are blocks to build the mobile app for dev, staging, and production:

  // environment: 'dev',
  // apiBaseUrl: 'https://br-admin-dev.artisanapps.io',
  // webAppBaseUrl: 'https://br-app-dev.artisanapps.io',
  // wtcDisable: true

  // environment: 'staging',
  // apiBaseUrl: 'https://cinim.artisanapps.io',
  // webAppBaseUrl: 'https://cinim-app.artisanapps.io',
  // wtcDisable: true
};


console.log(env);

function envBackgroundColorStyle () {
  return env.environment === 'local'
    ? { backgroundColor: '#ffed4a' }
    : env.environment === 'dev'
      ? { backgroundColor: '#6cb2eb' }
      : env.environment === 'staging'
        ? { backgroundColor: '#38c172' }
        : {};
}

export { env, envBackgroundColorStyle };
