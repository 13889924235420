import React from 'react';
import { ScrollView, View, StyleSheet, Platform } from 'react-native';

import { Heading } from '../components/heading';
import { Paragraph } from '../components/paragraph';
import { useSpaceForKeyboard } from '../hooks/useSpaceForKeyboard';
import {
  ScreenSizes,
  useScreenDimensions,
  useScreenSize,
  useStylesForWidth,
} from '../hooks/useStyleForWidth';
import { LoginImage } from '../img/login/LoginImage';
import { LogoImage } from '../img/logo/LogoImage';
import { LogoWithNameImage } from '../img/logo-with-name/LogoWithNameImage';
import { LogoWithNameWhiteImage } from '../img/logo-with-name-white/LogoWithNameWhiteImage';
import { useStaticTranslation } from '../services/staticTranslations';
import { AppColors, AppStyles } from '../services/styles';
import { envBackgroundColorStyle } from '../util/env';

const AnonymousScreen = function (props) {
  const { avoidKeyboard, welcomeMessageForSM, children, hideLogo } = props;
  const spaceForKeyboard = useSpaceForKeyboard(!avoidKeyboard || Platform.OS !== 'ios');
  const isSM = useScreenSize() === ScreenSizes.SM;
  const screenHeight = useScreenDimensions().height;
  const logoViewStyle = useStylesForWidth(styles, 'olLogoView', envBackgroundColorStyle());

  const backgroundImage = <LoginImage style={useStylesForWidth(styles, 'anonImage')} />;

  const welcomeTitle = useStaticTranslation('Welcome to InnerAccess');
  const welcomeView =
    welcomeMessageForSM && isSM ? (
      <View
        style={[
          styles.anonWelcomeView,
          { height: Math.max(screenHeight - 250, screenHeight * 0.75) },
        ]}>
        <LogoWithNameWhiteImage style={styles.anonWelcomeLogo} />
        <Heading style={styles.anonWelcomeHeading}>{welcomeTitle}</Heading>
        <Paragraph style={styles.anonWelcomeSubtitle}>{welcomeMessageForSM}</Paragraph>
      </View>
    ) : null;

  const view = (
    <View style={useStylesForWidth(styles, 'anonView', { minHeight: screenHeight })}>
      {isSM ? <View style={styles.olOuterView}>{welcomeView}</View> : null}

      <View style={useStylesForWidth(styles, 'olView')}>
        {hideLogo ? null : (
          <View style={logoViewStyle}>
            {isSM ? <LogoImage style={styles.olLogoSM} /> : <LogoWithNameImage />}
          </View>
        )}
        <View style={useStylesForWidth(styles, 'olInnerView')}>{children}</View>
      </View>
      <View style={useStylesForWidth(styles, 'anonSpacer')} />
    </View>
  );

  if (Platform.OS === 'web') {
    return (
      <>
        {backgroundImage}
        {view}
      </>
    );
  }
  return (
    <>
      {backgroundImage}
      <ScrollView
        style={[styles.anonScrollView, { marginBottom: spaceForKeyboard }]}
        contentInsetAdjustmentBehavior="never">
        {view}
      </ScrollView>
    </>
  );
};
const styles = StyleSheet.create({
  anonScrollView: {
    flex: 1,
  },
  anonView: {
    flex: 1,
  },
  anonViewMDLGXL: {
    flexDirection: 'row',
  },
  anonImage: {
    backgroundColor: AppColors.brandedBlue,
    zIndex: 0,
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
  },
  anonImageMDLGXL: {
    height: '150%',
  },
  anonSpacerSM: {
    display: 'none',
  },
  anonSpacerMDLGXL: {
    flex: 56,
  },
  olOuterView: {
    minHeight: 100,
    zIndex: 1,
  },
  anonWelcomeView: {
    padding: AppStyles.modalPaddingSM,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  anonWelcomeHeading: {
    color: AppColors.white,
    textAlign: 'center',
  },
  anonWelcomeLogo: {
    marginBottom: 30,
  },
  anonWelcomeSubtitle: {
    marginBottom: 30,
    color: AppColors.white,
    textAlign: 'center',
  },
  olView: {
    backgroundColor: AppColors.white,
    flex: 1,
    zIndex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  olViewSM: {
    borderTopRightRadius: AppStyles.largeBorderRadius,
    borderTopLeftRadius: AppStyles.largeBorderRadius,
    ...(Platform.OS === 'web'
      ? {}
      : {
          borderBottomRightRadius: AppStyles.largeBorderRadius,
          borderBottomLeftRadius: AppStyles.largeBorderRadius,
          paddingBottom: 80,
          marginBottom: -80,
        }),
  },
  olViewMDLGXL: {
    maxWidth: 634,
    flex: 44,
    borderTopRightRadius: AppStyles.largeBorderRadius,
    borderBottomRightRadius: AppStyles.largeBorderRadius,
  },
  olLogoViewSM: {
    justifyContent: 'center',
    flexDirection: 'row',
  },
  olLogoViewMDLG: {
    flexBasis: 150,
    height: 150,
    paddingBottom: 30,
  },
  olLogoViewMDLGXL: {
    marginLeft: '5%',
    width: '55%',
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 265,
    justifyContent: 'flex-end',
  },
  olLogoViewXL: {
    flexBasis: 210,
    height: 210,
    paddingBottom: 50,
  },
  olInnerView: {
    minWidth: 265,
  },
  olInnerViewSM: {
    width: '100%',
    padding: AppStyles.modalPaddingSM,
  },
  olInnerViewMDLGXL: {
    paddingBottom: 100,
    marginLeft: '5%',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto',
    width: '55%',
  },
  olLogoSM: {
    height: 50,
    width: 50,
    marginTop: AppStyles.modalPaddingSM - 10,
    marginBottom: 10 - AppStyles.modalPaddingSM,
  },
});

export { AnonymousScreen };
