import React from 'react';
import { StyleSheet } from 'react-native';

import { useLocale } from '../../hooks/useLocale';
import { useStaticTranslation } from '../../services/staticTranslations';
import { Languages } from '../../util/enums';

const LogoWithNameWhiteImage = function ({ style }) {
  const useFrench = useLocale() === Languages.french;
  const oneX = useFrench
    ? require('./logo-with-name-white-fr.png')
    : require('./logo-with-name-white.png');
  const twoX = useFrench
    ? require('./logo-with-name-white-fr@2x.png')
    : require('./logo-with-name-white@2x.png');
  const threeX = useFrench
    ? require('./logo-with-name-white-fr@3x.png')
    : require('./logo-with-name-white@3x.png');
  const dimensions = useFrench ? { height: 'auto', width: 266 } : { height: 'auto', width: 266 };
  return (
    <img
      src={threeX}
      style={StyleSheet.flatten([dimensions, style])}
      srcSet={`${oneX}, ${twoX} 2x, ${threeX} 3x`}
      alt={useStaticTranslation('InnerAccess')}
    />
  );
};

export { LogoWithNameWhiteImage };
