import React from 'react';
import { StyleSheet } from 'react-native';

const DashboardBannerImage = function ({ style }) {
  const oneX = require('./dashboard-banner.jpg');
  const twoX = require('./dashboard-banner@2x.jpg');
  return (
    <img
      src={twoX}
      alt=""
      style={{ ...StyleSheet.flatten(style), objectFit: 'cover' }}
      srcSet={`${oneX}, ${twoX} 2x`}
    />
  );
};

export { DashboardBannerImage };
