import { useEffect } from 'react';

import { useStaticTranslation } from '../services/staticTranslations';
import { env } from '../util/env';

function useScreenTitle(title, defaultPrefix = true) {
  const appName = useStaticTranslation('InnerAccess');
  useEffect(() => {
    const setTitle = t => {
      const parts = [];
      if (defaultPrefix || !t) {
        parts.push(appName);
      }
      if (t) {
        parts.push(t);
      }
      const suffix = env.environment !== 'production' ? `(${env.environment})` : '';
      if (suffix) {
        parts.push(suffix);
      }
      document.title = parts.join(' ');
    };
    setTitle(title);

    return () => setTitle();
  }, [title, defaultPrefix, appName]);
}

export { useScreenTitle };
