import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';

import { useStaticTranslation } from '../services/staticTranslations';
import { env } from '../util/env';

function dec2hex(dec) {
  return ('0' + dec.toString(16)).substr(-2); //convert a number to a 2 character hexadecimal string
}

function generateRandomString() {
  //need a crypto-quality random string from 43-128 characters, A-Za-z0-9-._~
  const array = new Uint32Array(78 / 2); //create an empty array with 39 members
  window.crypto.getRandomValues(array); //fill the empty array with random numbers
  return Array.from(array, dec2hex).join(''); //convert each number to a 2 char hex string and concatenate
}

function sha256(content) {
  //returns an ArrayBuffer via promise
  const encoder = new TextEncoder();
  const data = encoder.encode(content); //encode the content string into a Uint8Array of bytes
  return window.crypto.subtle.digest('SHA-256', data); //create and return the SHA256 digest
}

function base64UrlEncode(shaDigest) {
  //convert the shaDigest bytes to characters
  let str = '';
  const bytes = new Uint8Array(shaDigest);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }

  //base64 url encode the string
  return btoa(str).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

const WtcLoginButton = function (props) {
  const { style } = props;
  const [working, setWorking] = useState(false);
  const [challenge, setChallenge] = useState(null);

  const params = {
    client_id: env.wtcClientId,
    redirect_uri: env.webAppBaseUrl + '/wtc_login',
    response_type: 'code',
    scope: 'openid email',
    // state: 'nothing', //could use this to pass info to WTCLoginScreen if we like
    code_challenge: challenge,
    code_challenge_method: 'S256', //can use 'plain' in dev environment (code verifier and challenge are the same)
    response_mode: 'query', //could also be 'fragment' to receive code as a hashtag
  };
  const authorizeUrl =
    env.wtcBaseUrl + '/oauth2/authorize?' + new URLSearchParams(params).toString();

  useEffect(() => {
    if (challenge) {
      if (env.environment === 'local') {
        document.location.href = '/wtc_login?code=local';
      } else {
        document.location.href = authorizeUrl;
      }
    }
  }, [challenge, authorizeUrl]);

  function declineUser() {
    if (env.environment !== 'local') {
      alert(
        'It looks your browser does not support the functionality required for this feature. Please update your browser or try a different modern browser like Chrome, Firefox, Edge, or Safari.'
      );
      setWorking(false);
    } else {
      setChallenge('local');
    }
  }

  const startLogin = () => {
    if (!working) {
      setWorking(true);

      try {
        const v = generateRandomString();
        window.localStorage.setItem('wtc_verifier', v);
        sha256(v)
          .then(shaDigest => {
            setChallenge(base64UrlEncode(shaDigest));
          })
          .catch(declineUser);
      } catch {
        declineUser();
      }
    }
  };

  // noinspection CheckTagEmptyBody
  return (
    <a
      className="wtc-signin-anchor"
      onClick={event => {
        event.preventDefault();
        startLogin();
      }}
      style={StyleSheet.flatten(style)}
      href={authorizeUrl}>
      <svg className="wtc-signin-leaf" viewBox="-2015 -2000 4030 4030">
        <path
          className="wtc-signin-leaf-path"
          d="m-90 2030 45-863a95 95 0 0 0-111-98l-859 151 116-320a65 65 0 0 0-20-73l-941-762 212-99a65 65 0 0 0 34-79l-186-572 542 115a65 65 0 0 0 73-38l105-247 423 454a65 65 0 0 0 111-57l-204-1052 327 189a65 65 0 0 0 91-27l332-652 332 652a65 65 0 0 0 91 27l327-189-204 1052a65 65 0 0 0 111 57l423-454 105 247a65 65 0 0 0 73 38l542-115-186 572a65 65 0 0 0 34 79l212 99-941 762a65 65 0 0 0-20 73l116 320-859-151a95 95 0 0 0-111 98l45 863z"
        />
      </svg>
      <span>{useStaticTranslation('Sign in with WTC')}</span>
    </a>
  );
};

export { WtcLoginButton };
