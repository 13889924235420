import React, { useState, useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { Heading } from '../shared/components/heading';
import { TextField } from '../shared/components/textField';
import { ApiService, useApiRequest } from '../shared/services/apiService';
import { ActionButton } from '../shared/components/actionButton';
import { WarningText } from '../shared/components/warningText';
import { IconNames } from '../shared/services/styles';
import { useScreenTitle } from '../shared/hooks/useScreenTitle';
import { Paragraph } from '../shared/components/paragraph';
import { openUrlAction } from '../shared/util/utils';
import { env } from '../shared/util/env';
import { ActionLink } from '../shared/components/actionLink';
import { Checkbox } from '../shared/components/checkbox';
import { useScreenView } from '../shared/hooks/useScreenView';
import { AnalyticsScreenNames } from '../shared/util/enums';
import { HeadingType } from '../shared/components/heading';
import { USER_FIELD_EMAIL, useUserInfo } from '../shared/services/apiService';
import { OrLine } from '../shared/components/orLine';
import { ActionButtonType } from '../shared/components/actionButton';
import { USER_FIELD_PREFERRED_LANGUAGE } from '../shared/services/apiService';
import { LanguageToggleField } from '../shared/components/languageToggleField';
import { staticTranslation } from '../shared/services/staticTranslations';
import { useLocale, setLocale } from '../shared/hooks/useLocale';

let WTCRegisterScreen = function (props) {
  const locale = useLocale();
  useScreenTitle('WTC ' + staticTranslation(locale, 'Registration'));
  useScreenView(AnalyticsScreenNames.wtcRegister, 'WTCRegisterScreen');
  const preferredLanguage = useUserInfo(USER_FIELD_PREFERRED_LANGUAGE);
  const [editPreferredLanguage, setEditPreferredLanguage] =
    useState(preferredLanguage);
  const email = useUserInfo(USER_FIELD_EMAIL);
  const [name, setName] = useState('');
  const [agreedToConditions, setAgreedToConditions] = useState(false);
  const [viewedTermsOfUse, setViewedTermsOfUse] = useState(false);
  const [viewedPrivacyPolicy, setViewedPrivacyPolicy] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [warning, setWarning] = useState('');
  const registerRequest = useApiRequest(
    (cb) => {
      return ApiService.updateCurrentUser(
        email,
        name,
        marketing,
        agreedToConditions,
        editPreferredLanguage,
        cb
      );
    },
    [email, name, agreedToConditions, marketing]
  );

  useEffect(() => {
    setWarning('');
  }, [locale]);

  useEffect(() => {
    props.setHideLogo(false);
    props.setWelcomeMessageForSM(null);
    props.setAvoidKeyboard(true);
  }, [props]);

  return (
    <>
      <Paragraph style={styles.regIntro}>
        {staticTranslation(
          locale,
          'Thanks for signing up for InnerAccess! We just need a couple of things before you get started.'
        )}
      </Paragraph>
      <Heading type={HeadingType.FOUR}>
        {staticTranslation(locale, 'Name:')}
      </Heading>
      <TextField
        autoFocusHardwareKeyboards={true}
        inputProps={{
          disabled: registerRequest.isBusy,
          maxLength: 100,
          blurOnSubmit: !!name,
          returnKeyType: 'next',
          placeholder: staticTranslation(locale, 'Full Name'),
          value: name,
          onChangeText: (n) => {
            setWarning('');
            setName(n);
          },
          autoComplete: 'name',
          autoCompleteType: 'name',
          textContentType: 'name',
          enablesReturnKeyAutomatically: true,
        }}
      />
      <Heading type={HeadingType.FOUR}>
        {staticTranslation(locale, 'Email Notifications:')}
      </Heading>
      <Checkbox
        title={staticTranslation(
          locale,
          'I agree to allow InnerAccess to send me important emails from time to time.'
        )}
        style={styles.regCheckbox}
        checked={marketing}
        onChange={() => setMarketing((m) => !m)}
      />
      <Heading type={HeadingType.FOUR}>
        {staticTranslation(locale, 'Language Preference:')}
      </Heading>
      <LanguageToggleField
        selectedLanguage={editPreferredLanguage}
        onToggle={(pref) => {
          setEditPreferredLanguage(pref);
          setLocale(pref, true);
        }}
      />
      <Heading type={HeadingType.FOUR}>
        {staticTranslation(locale, 'Terms of use and privacy policy:')}
      </Heading>
      <Paragraph>
        {staticTranslation(
          locale,
          'You must read and agree to our Terms of Use and Privacy Policy in order to complete your registration.'
        )}
      </Paragraph>
      <ActionLink
        style={styles.regViewPolicyAction}
        rightIcon={viewedTermsOfUse ? IconNames.checkmark : IconNames.openInNew}
        action={() => {
          setWarning('');
          setViewedTermsOfUse(true);
          openUrlAction(env.termsOfUseUrl)();
        }}
      >
        {staticTranslation(locale, 'View Terms of Use')}
      </ActionLink>
      <ActionLink
        style={styles.regViewPolicyAction}
        rightIcon={
          viewedPrivacyPolicy ? IconNames.checkmark : IconNames.openInNew
        }
        action={() => {
          setWarning('');
          setViewedPrivacyPolicy(true);
          openUrlAction(env.privacyPolicyUrl)();
        }}
      >
        {staticTranslation(locale, 'View Privacy Policy')}
      </ActionLink>
      <WarningText>
        {warning ||
          (registerRequest.error
            ? `${staticTranslation(
                locale,
                'There was a problem saving this registration:'
              )} ${registerRequest.error}`
            : null)}
      </WarningText>
      <ActionButton
        title={staticTranslation(locale, 'Agree and Finish Registration')}
        action={() => {
          if (!name) {
            setWarning(
              staticTranslation(
                locale,
                'Oops, your need to enter your name to finish registration!'
              )
            );
          } else if (!viewedPrivacyPolicy || !viewedTermsOfUse) {
            setWarning(
              staticTranslation(
                locale,
                'Oops, you need to read our Terms of Use and Privacy Policy to finish registration!'
              )
            );
          } else {
            setAgreedToConditions(true);
            setWarning('');
            registerRequest.send();
          }
        }}
        showActivityIndicator={registerRequest.isBusy}
        disabled={registerRequest.isBusy}
      />
      <OrLine />
      <ActionButton
        action={() => props.navigateTo('/logout')}
        disabled={registerRequest.isBusy}
        type={ActionButtonType.SECONDARY}
        title={staticTranslation(locale, 'Logout')}
      />
    </>
  );
};

const styles = StyleSheet.create({
  regViewPolicyAction: {
    marginBottom: 10,
  },
  regCheckbox: {
    marginBottom: 20,
  },
  regIntro: {
    marginBottom: 20,
  },
});

export { WTCRegisterScreen };
