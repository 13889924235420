import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../App.css';
import { View } from 'react-native';
import { ScreenLoadingIndicator } from '../shared/components/screenLoadingIndicator';
import { AppError } from '../shared/components/appError';
import { env } from '../shared/util/env';
import { ApiService, useApiRequest } from '../shared/services/apiService';
import { useLocale } from '../shared/hooks/useLocale';
import { staticTranslation } from '../shared/services/staticTranslations';

const wtcAxios = axios.create({
  baseURL: env.wtcBaseUrl,
  withCredentials: false,
});
delete wtcAxios.defaults.headers.common['X-BR-Client-Info'];
delete axios.defaults.headers.common.Authorization;

let WTCLoginScreen = function () {
  const [gettingToken, setGettingToken] = useState(false);
  const [token, setToken] = useState(
    env.environment === 'local' ? 'local' : null
  );
  let queryParams = new URLSearchParams(document.location.search);
  const authorizationCode = queryParams.get('code'); //note that state will also be accessible here if we pass it in WTCLoginButton, could use a random string and test for extra verification
  const verifier = window.localStorage.getItem('wtc_verifier');
  const [error, setError] = useState(
    !authorizationCode || !verifier
      ? 'Authorization code or verifier missing'
      : null
  );

  const locale = useLocale();

  const loginRequest = useApiRequest(
    (cb) => {
      return ApiService.wtcLogin(token, locale, cb);
    },
    [token, locale]
  );

  useEffect(() => {
    if (!token && !gettingToken && !error) {
      setGettingToken(true);
      const tokenRequestBodyParams = new URLSearchParams({
        client_id: env.wtcClientId,
        code: authorizationCode,
        redirect_uri: env.webAppBaseUrl + '/wtc_login', //same as specified on WTCLoginButton
        code_verifier: verifier,
        grant_type: 'authorization_code',
      });

      wtcAxios
        .post('/oauth2/token', tokenRequestBodyParams)
        .then((response) => {
          /*
                sample response:
                {
                    "access_token": "ofAACwMbwWHgvPlI98tXsiyBQbF6fZ",
                    "expires_in": 36000,
                    "token_type": "Bearer",
                    "scope": "openid email",
                    "refresh_token": "iEj9jPQbSFNRVUI1NGkxv00vIG9gAd",
                    "id_token": "jwt token as some long hex string"
                }
                 */
          if (!response.data.id_token) {
            throw new Error('No id_token');
          }
          setToken(response.data.id_token);
          setGettingToken(false);
        })
        .catch((error) => {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.error('error.response.data:', error.response.data);
            console.error('error.response.status:', error.response.status);
            console.error('error.response.headers:', error.response.headers);
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.error('error.request:', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('error.message', error.message);
          }
          console.error('error.config:', error.config);
          setError(error.message);
          setGettingToken(false);
        });
    }
  }, [gettingToken, error, token, authorizationCode, verifier]);

  const loginError = loginRequest.error;
  useEffect(() => {
    if (loginError) {
      setError(loginError);
    }
  }, [loginError]);

  const { isBusy, succeeded, send } = loginRequest;
  useEffect(() => {
    if (token && !error && !isBusy && !succeeded) {
      send();
    }
  }, [token, error, isBusy, succeeded, send]);

  const isNewUser = (loginRequest.data || {}).is_new_user;
  useEffect(() => {
    if (succeeded) {
      if (isNewUser) {
        document.location.href = '/users/me';
      } else {
        document.location.href = '/';
      }
    }
  }, [succeeded, isNewUser]);

  return error ? (
    <AppError
      action={() => (document.location = '/wellness_together_canada')}
      actionTitle={staticTranslation('Try Again')}
      error={error}
      message={staticTranslation(
        'Please return to the Wellness Together Canada welcome screen and try again.'
      )}
    />
  ) : (
    <div className="App">
      <View style={{ margin: 50 }}>
        <div style={{ marginTop: 100 }}>
          <ScreenLoadingIndicator center={true} />
        </div>
      </View>
    </div>
  );
};

export { WTCLoginScreen };
